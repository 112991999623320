import * as React from 'react';
import type { PageProps } from 'gatsby';

// @ts-ignore
import dirtCheapLogo from '../../images/dirtcheap-logo.svg';
// @ts-ignore
import footerImage1 from '../../images/otr-footer-1.png';
// @ts-ignore
import footerImage2 from '../../images/otr-footer-2.png';
// @ts-ignore
import footerImage3 from '../../images/otr-footer-3.png';
import Layout from '../../layouts/Layout';
// @ts-ignore
import hero from '../../images/otr-hero.png';
import { graphql, useStaticQuery } from 'gatsby';
import JobSearch from '../../components/JobSearch/JobSearch';
import HeroBanner from '../../components/HeroBanner/HeroBanner';
// @ts-ignore
import arrow from '../../images/blue-right-arrow.svg';
const SearchPage: React.FC<PageProps> = () => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);
	return (
		<Layout
			title={'On the Run | Employment Opportunities'}
			desc={'On the Run | Employment Opportunities'}
		>
			<HeroBanner />
			<section className={'px-5'}>
				<div className={'container mx-auto py-12'}>
					<a
						href="/"
						className="text-dirtcheaptext hover:text-dirtcheaptext max-w-[100px] text-[20px]"
					>
						<button className="flex flex-row items-center">
							<img
								src={arrow}
								width={16}
								className="mr-[6px] rotate-180"
							/>
							Return to Map
						</button>
					</a>
					<h2 className={'font-dinblack text-5xl text-otr-blue'}>
						SEARCH RESULTS
					</h2>
				</div>
			</section>
			<JobSearch data={data} />
		</Layout>
	);
};

export default SearchPage;
